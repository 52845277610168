import request from '@/utils/request'

/**
 * 产品列表
 */
export const productList = (data) => {
  return request({
    url: `/admin/product/list?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 产品导入
 */
export const productImport = (data) => {
  return request({
    url: '/admin/product/import',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 更新价格
 */
export const productUpdatePrice = (data) => {
  return request({
    url: '/admin/product/update/price',
    method: 'POST',
    data
  })
}

/**
 * 删除产品
 */
export const productDelete = (data) => {
  return request({
    url: '/admin/product/delete',
    method: 'POST',
    data
  })
}
