<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="产品名称">
          <el-input
            v-model="formInline.user"
            placeholder="请输入产品名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择更新时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container">
        <el-button type="primary" :icon="Upload" @click="handleImport">
          导入产品
        </el-button>
        <el-button
          type="danger"
          plain
          :disabled="!multipleSelection.length"
          :icon="Delete"
          @click="onBethDelete"
        >
          删除
        </el-button>
      </div>
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border
        v-loading="loading"
        element-loading-text="正在加载中..."
        style="width: 100%"
        header-cell-class-name="table-header"
        @cell-click="onCellEditClick"
        @selection-change="onSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="id" label="产品ID" align="center" />
        <el-table-column prop="skinId" label="皮肤ID" align="center">
        </el-table-column>
        <el-table-column prop="deviceId" label="设备ID" align="center">
        </el-table-column>
        <el-table-column label="产品价格" align="center">
          <template #default="{ row, column }">
            <div
              class="table-edit-input"
              v-if="
                tableRowEditId === row.id && tableColumnEditIndex === column.id
              "
            >
              <el-input
                autofocus
                size="small"
                placeholder="请输入内容"
                v-model="row.price"
                @keyup.enter="blurValueInput(row)"
              />
              <el-icon
                class="edit-icon"
                size="16"
                color="#009688"
                @click="blurValueInput(row)"
              >
                <Check />
              </el-icon>
            </div>
            <span v-else>{{ row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template #default="{ row }">
            {{ row.createTime ? $filters.dateFilter(row.createTime) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center">
          <template #default="{ row }">
            {{ row.updateTime ? $filters.dateFilter(row.updateTime) : '--' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300" align="center">
          <template #default="{row}">
            <el-button
              type="primary"
              link
              :icon="Delete"
              @click="onRemoveClick(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[2, 5, 10, 20]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      v-model="centerDialogVisible"
      title="产品导入"
      width="500"
      align-center
    >
      <el-upload
        v-model:file-list="fileList"
        ref="uploadRef"
        drag
        action=""
        :limit="1"
        :auto-upload="false"
        accept=".xlsx,.xls"
      >
        <el-icon class="el-icon--upload"><UploadFilled /></el-icon>
        <div class="el-upload__text">
          将文件拖拽到此处 或者 <em>点击上传</em>
        </div>
      </el-upload>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleUpload">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import {
  Delete,
  Search,
  Upload,
  Check,
  UploadFilled
} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  productList,
  productImport,
  productUpdatePrice,
  productDelete
} from '@/api/product'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const centerDialogVisible = ref(false)
const loading = ref(false)
const tableData = ref([])
const page = reactive({
  pageIndex: 1,
  pageSize: 10,
  total: 0
})

const handleImport = () => {
  centerDialogVisible.value = true
}
const fileList = ref([])
const handleUpload = async () => {
  // productImport
  centerDialogVisible.value = false
  // console.log('upload', fileList.value[0].raw)
  await productImport({ file: fileList.value[0].raw })
}

const updatePrice = (deviceId, price) => {
  const params = {
    deviceId,
    price
  }
  tableRowEditId.value = null
  tableColumnEditIndex.value = null
  loading.value = true
  productUpdatePrice(params)
    .then(res => {
      ElMessage.success('修改成功')
      loading.value = false
      getListData()
    })
    .finally(() => {
      loading.value = false
    })
}

const tableRowEditId = ref(null) // 控制可编辑的每一行
const tableColumnEditIndex = ref(null) // 控制可编辑的每一列
const onCellEditClick = (row, column) => {
  tableRowEditId.value = row.id
  tableColumnEditIndex.value = column.id
}
const blurValueInput = row => {
  updatePrice(row.deviceId, row.price)
}

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  const params = {
    pageIndex: page.pageIndex,
    pageSize: page.pageSize
  }
  const res = await productList(params)
  tableData.value = res.datas
  page.total = res.records
}
getListData()
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  page.pageSize = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.pageIndex = currentPage
  getListData()
}

const multipleTableRef = ref(null)

/**
 * 删除产品
 */
const onDeleteProduct = async ids => {
  await productDelete({ ids })
  ElMessage.success('删除成功')
  multipleSelection.value = []
  multipleTableRef.value.clearSelection()
  // 重新渲染数据
  getListData()
}
const multipleSelection = ref([])
const onSelectionChange = val => {
  multipleSelection.value = val
}

/**
 * 批量删除产品
 */
const onBethDelete = () => {
  const ids = multipleSelection.value.map(item => item.id)
  if (ids.length === 0) {
    ElMessage.warning('请选择要删除的产品')
    return
  }
  ElMessageBox.confirm('确定要删除选中的产品吗？', '提示', {
    type: 'warning'
  }).then(async () => {
    await onDeleteProduct(ids)
  })
}

/**
 * 删除
 */
const onRemoveClick = row => {
  console.log(row)
  ElMessageBox.confirm(
    `确定要删除产品id为：${row.skinId}，这条数据吗？`,
    '提示',
    {
      type: 'warning'
    }
  ).then(async () => {
    await onDeleteProduct([row.id])
  })
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }
  .table-edit-input {
    display: flex;
    align-items: center;
    justify-content: center;
    &:deep(.el-input) {
      width: 80px;
    }
    &:deep(.el-input--small .el-input__inner) {
      text-align: center;
    }
    .edit-icon {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
